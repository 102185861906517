import { STATUSES } from '@constants/slices'
import { Box, Card, CircularProgress, Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect } from 'react'
import Pagination from '@mui/material/Pagination'
import { FormattedMessage, useIntl } from 'react-intl'
import UserCoins from '@components/atoms/UserCoins'
import MessageFromHero from '@components/atoms/MessageFromHero'

const useStyles = makeStyles(theme => ({
  CardCourses: {
    marginBottom: 16,
    minHeight: 214,
    boxShadow: '0px 3px 6px rgba(0,0,0,0.16)',
    '-webkit-box-shadow': '0px 3px 6px rgba(0,0,0,0.16)',
    margin: `0px ${theme.spacing(2)} 16px ${theme.spacing(2)}`,
    [theme.breakpoints.down('md')]: {
      margin: `0px ${theme.spacing(1)} 16px ${theme.spacing(1)}`,
    },
  },
  CardCourses_HeaderWrapper: {
    justifyContent: 'space-between',
    [theme.breakpoints.down(410)]: {
      justifyContent: 'center',
    },
  },
  CardCourses_StatusContainer: {
    minHeight: 214,
  },
  CardCourses_Title: {
    padding: '16px',
    fontSize: '34px',
    lineHeight: '45px',
    paddingBottom: '8px',
    [theme.breakpoints.down(550)]: {
      fontSize: '20px',
      lineHeight: '26px',
      padding: '8px 16px',
    },
  },
  CardCourses_CardsWrapper: {
    padding: '0 16px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(1)}`,
    },
  },
  CardCourses_ItemContainerWrapper: {
    width: 'calc(50% - 8px)',
    [theme.breakpoints.down(960)]: {
      width: '100%',
    },
  },
  CardCourses_PaginationContainer: {
    marginBottom: 16,
  },
  CardCourses_PaginationWrapper: {
    '&>nav>ul>li>button.MuiPaginationItem-root': {
      [theme.breakpoints.down(550)]: {
        height: '26px',
        margin: '0 1px',
        padding: '0 4px',
        minWidth: '26px',
        borderRadius: '13px',
        fontSize: '14px',
      },
    },
  },
  CardCourses_HiddenBox: {
    width: '641px',
  },
  ul: {
    '& .MuiPaginationItem-root': {
      color: '#fff',
      backgroundColor: theme.palette.secondary.main,
    },
  },
  ShopCardCourses_MessageWrapper: {
    padding: theme.spacing(2),
  },
}))

const ITEMS_ON_PAGE = 10

const ShopCardCourses = ({
  courses = [],
  status,
  freeSelected,
  itemCard: ItemCard,
  userCoins,
  clickHandler,
  subClickHandler,
  startOnPage,
}) => {
  const c = useStyles()
  const coursesLength = courses.length
  const intl = useIntl()

  const [page, setPage] = React.useState(1)
  const [countPages, setCountPages] = React.useState(0)

  useEffect(() => {
    if (startOnPage) {
      setPage(startOnPage + 1)
    } else setPage(1)
  }, [freeSelected, startOnPage])

  useEffect(() => {
    setCountPages(Math.floor(coursesLength / ITEMS_ON_PAGE))
  }, [coursesLength])

  const handleChange = (event, value) => {
    setPage(value)
  }

  const buildMsg = () => {
    if (freeSelected) {
      return intl.formatMessage({ id: 'shared.alreadySubscripedToAllFree' })
    } else {
      return intl.formatMessage({ id: 'shared.alreadyOwnAllCourses' })
    }
  }

  return (
    <>
      <Card className={c.CardCourses} square elevation={0} id='ShopCardCoursesROOT'>
        {status === STATUSES.LOADING && (
          <Grid
            className={c.CardCourses_StatusContainer}
            container
            justifyContent='center'
            alignItems='center'
          >
            <CircularProgress />
          </Grid>
        )}
        {status === STATUSES.ERROR && (
          <Grid
            className={c.CardCourses_StatusContainer}
            container
            justifyContent='center'
            alignItems='center'
          >
            <Typography component='p'>
              <FormattedMessage id='shared.somethingWrong' />
            </Typography>
          </Grid>
        )}
        {status === STATUSES.LOADED && (
          <Box>
            <Grid
              container
              className={c.CardCourses_HeaderWrapper}
              direction='row'
              alignItems='center'
            >
              <Typography className={c.CardCourses_Title}>
                <FormattedMessage
                  id={freeSelected ? 'shop.freeCourses' : 'shop.paidCourses'}
                />
              </Typography>
              <UserCoins coins={userCoins}></UserCoins>
            </Grid>
            {courses.length > 0 ? (
              <>
                <Grid
                  container
                  wrap='wrap'
                  justifyContent='space-between'
                  className={c.CardCourses_CardsWrapper}
                >
                  {courses
                    .slice(page * ITEMS_ON_PAGE - ITEMS_ON_PAGE, page * ITEMS_ON_PAGE)
                    .map(course => (
                      <Grid
                        item
                        key={course?.id}
                        className={c.CardCourses_ItemContainerWrapper}
                      >
                        {ItemCard && (
                          <ItemCard
                            course={course}
                            isFree={freeSelected}
                            handleClick={clickHandler}
                            handleSubClick={subClickHandler}
                          ></ItemCard>
                        )}
                      </Grid>
                    ))}
                  {coursesLength % 2 === 1 && (
                    <Box className={c.CardCourses_HiddenBox}></Box>
                  )}
                </Grid>
                {courses.length > ITEMS_ON_PAGE && (
                  <Grid
                    className={c.CardCourses_PaginationContainer}
                    container
                    justifyContent='center'
                  >
                    <Grid item className={c.CardCourses_PaginationWrapper}>
                      <Pagination
                        count={countPages + 1}
                        page={page}
                        onChange={handleChange}
                        color='primary'
                        size='large'
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            ) : (
              <div className={c.ShopCardCourses_MessageWrapper}>
                <MessageFromHero message={buildMsg()}></MessageFromHero>
              </div>
            )}
          </Box>
        )}
      </Card>
    </>
  )
}

export default ShopCardCourses
