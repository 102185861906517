import React from 'react'
import { Grid, Switch, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  CookieBanner_Detailitem: {
    margin: '2px 0px',
    backgroundColor: theme.palette.background.colored,
  },
  CookieBanner_Detailitem_text: {
    padding: `0px ${theme.spacing(1)}`,
    [theme.breakpoints.down(550)]: {
      fontSize: 14,
    },
  },
  CookieBanner_Detailitem__grow: {
    flex: '1 0 50px',
  },
  CookieBanner_Detailitem__stay_small: {
    flex: '0 0 64px',
    maxWidth: '64px',
  },
  CookieBanner_Detailitem__stay: {
    flex: '0 0 64px',
    maxWidth: '64px',
    [theme.breakpoints.down(550)]: {
      flex: '0 0 56px',
      maxWidth: '56px',
    },
  },
}))

export const CookieDetailRow = ({ checked = null, setChecked, info, time }) => {
  const c = useStyles()
  return (
    <Grid item container direction='row' justifyContent='start' className gap={'4px'}>
      <Grid
        item
        container
        className={clsx(c.CookieBanner_Detailitem, c.CookieBanner_Detailitem__stay_small)}
        justifyContent='center'
        alignItems='center'
      >
        {checked !== null && setChecked && (
          <Switch
            checked={checked}
            onChange={event => setChecked(event.target.checked)}
          ></Switch>
        )}
      </Grid>
      <Grid
        item
        container
        className={clsx(c.CookieBanner_Detailitem, c.CookieBanner_Detailitem__grow)}
        alignItems='center'
      >
        <Typography className={c.CookieBanner_Detailitem_text}>{info}</Typography>
      </Grid>
      <Grid
        item
        container
        className={clsx(c.CookieBanner_Detailitem, c.CookieBanner_Detailitem__stay)}
        alignItems='center'
      >
        <Typography
          className={c.CookieBanner_Detailitem_text}
          style={{ textAlign: 'center' }}
        >
          {time}
        </Typography>
      </Grid>
    </Grid>
  )
}
