import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import DoneIcon from '@mui/icons-material/Done'

import { useIntl } from 'react-intl'
import { useTheme } from '@mui/styles'
import { Backdrop, Paper, Typography } from '@mui/material'

const useStyles = makeStyles(theme => ({
  DoneButton_Container: {
    height: '64px',
    width: '64px',
  },
  DoneButton_MainBase: {
    position: 'absolute',
    borderRadius: '50%',
    height: '64px',
    width: '64px',
    transition: '0.5s ease background-color',
  },
  DoneButton_Main_InActive: {
    backgroundColor: theme.palette.primary.main,
  },
  DoneButton_Main_Active: {
    backgroundColor: theme.palette.primary.green,
  },
  DoneButton_InnerBase: {
    position: 'absolute',
    borderRadius: '50%',
    backgroundColor: 'white',
    transition: '0.2s ease width, 0.2s ease height, 0.2s ease top, 0.2s ease left',
  },
  DoneButton_Inner: {
    height: '48px',
    width: '48px',
    top: 'calc((100% - 48px) /2)',
    left: 'calc((100% - 48px) /2)',
  },
  DoneButton_Inner_Hover: {
    height: '32px',
    width: '32px',
    top: 'calc((100% - 32px) /2)',
    left: 'calc((100% - 32px) /2)',
  },
  DoneButton_Inner_Hidden: {
    height: '0px',
    width: '0px',
    top: 'calc((100% - 0px) /2)',
    left: 'calc((100% - 0px) /2)',
  },
  DoneButton_CheckIcon: {
    fontSize: '48px',
    color: 'black',
  },
  DoneButton_CheckBase: {
    fontSize: '48px',
    color: 'black',
    position: 'absolute',
    transition: '0.6s ease transform',
    top: 'calc((100% - 48px) /2)',
    left: 'calc((100% - 48px) /2)',
  },
  DoneButton_Check_Active: {
    transform: 'scale(1) rotate(360deg)',
  },
  DoneButton_Check_InActive: {
    transform: 'scale(0) rotate(-360deg)',
  },
  DoneButton_ResetConfirm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '16px',
    margin: '8px',
  },
  DoneButton_ResetButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  DoneButton_ResetConfirmText: {
    fontSize: '18px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    [theme.breakpoints.down(600)]: {
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
  DoneButton_ConfirmButton: {
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    paddingBottom: '0px',
    paddingTop: '8px',
    textTransform: 'uppercase',
  },
}))

const DoneButton = ({ onClick = () => {}, progress = 0, title = '', id }) => {
  const c = useStyles()
  const theme = useTheme()
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const [isActive, setIsActive] = useState(progress === 1)

  useEffect(() => {
    setIsActive(progress === 1)
  }, [id])

  const handleMouseEnter = () => {
    setIsHover(true)
  }
  const handleMouseLeave = () => {
    setIsHover(false)
  }
  const handleClick = () => {
    if (isActive) {
      setOpen(true)
    } else {
      onClick(progress === 1 ? 0 : 1)
      setIsActive(!isActive)
    }
  }

  const resetHandler = () => {
    onClick(progress === 1 ? 0 : 1)
    setOpen(false)
    setIsActive(false)
  }

  return (
    <div className={c.DoneButton_Container}>
      <div
        className={clsx(
          c.DoneButton_MainBase,
          isActive ? c.DoneButton_Main_Active : c.DoneButton_Main_InActive
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <div
          className={clsx(
            c.DoneButton_InnerBase,
            isActive
              ? c.DoneButton_Inner_Hidden
              : isHover
                ? c.DoneButton_Inner_Hover
              : c.DoneButton_Inner
          )}
        ></div>
        <DoneIcon
          className={clsx(
            c.DoneButton_CheckBase,
            isActive ? c.DoneButton_Check_Active : c.DoneButton_Check_InActive
          )}
        ></DoneIcon>
      </div>
      <Backdrop style={{ zIndex: 2 }} open={open}>
        <Paper className={c.DoneButton_ResetConfirm}>
          <Typography className={c.DoneButton_ResetConfirmText}>
            {intl.formatMessage(
              { id: 'shared.resetTopicConfirm' },
              { title: <b>{title}</b> }
            )}
          </Typography>
          <div className={c.DoneButton_ResetButtonContainer}>
            <Button onClick={() => setOpen(false)}>
              <Typography className={c.DoneButton_ConfirmButton} color='primary'>
                {intl.formatMessage({ id: 'shared.no' })}
              </Typography>
            </Button>
            <Button onClick={resetHandler}>
              <Typography className={c.DoneButton_ConfirmButton} color='primary'>
                {intl.formatMessage({ id: 'shared.yes' })}
              </Typography>
            </Button>
          </div>
        </Paper>
      </Backdrop>
    </div>
  )
}

export default DoneButton
