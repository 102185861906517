import React from 'react'
import { makeStyles } from '@mui/styles'

import {  useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Avatar from 'react-avatar'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import CardActionArea from '@mui/material/CardActionArea'
import LockIcon from '@mui/icons-material/LockRounded'

import { useHistory } from 'react-router-dom'

import { useIntl, FormattedMessage } from 'react-intl'

import clsx from 'clsx'
import PriceInCoins from '@components/atoms/PriceInCoins'

const useStyles = makeStyles(theme => ({
  SearchItem_MainContainer: {
    flex: '0 0 calc(50%)',
    maxWidth: 'calc(50%)',
    backgroundColor: '#fff',
    padding: 0,
    color: '#000000',
    [theme.breakpoints.down('lg')]: {
      flex: '0 0 100%',
      maxWidth: '100%',
    },
  },
  SearchItem_MainContainer__NotFound: {
    flex: '0 0 100%',
    maxWidth: '100%',
    textAlign: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.down(960)]: { width: '100%' },
  },
  SearchItem_Icon: {
    height: theme.spacing(8),
    width: theme.spacing(8),
    [theme.breakpoints.down(768)]: {
      height: theme.spacing(6),
      width: theme.spacing(6),
    },
  },
  SearchItem_TitleWrapper__Centered: {
    width: '100%',
  },
  SearchItem_Title: {
    fontSize: '24px',
    lineHeight: '32px',
    maxWidth: '100%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down(960)]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.down(768)]: {
      fontSize: '16px',
      lineHeight: '22px',
      marginTop: theme.spacing(1),
    },
  },
  SearchItem_TypeOfCard: {
    fontSize: '14px',
    lineHeight: '19px',
    opacity: '0.87',
    textTransform: 'uppercase',
    [theme.breakpoints.down(768)]: {
      fontSize: '12px',
    },
  },
  SearchItem_ButtonContainer: {
    width: '100%',
    textAlign: 'left',
    minHeight: '40px',
    padding: `0px 0px ${theme.spacing(1)} 0px`,
  },
  SearchItem__OneLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down(960)]: {
      whiteSpace: 'normal',
    },
  },
  SearchItem__Centered: {
    width: '100%',
    height: '100%',
    paddingTop: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  SearchItem_CourseName: {
    fontSize: '16px',
    lineHeight: '21px',
    opacity: '0.6',
    marginBottom: '6px',
    [theme.breakpoints.down(768)]: {
      fontSize: '12px',
    },
  },
  SearchItem_Button: {
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    textTransform: 'uppercase',
    [theme.breakpoints.down(768)]: {
      fontSize: '12px',
    },
  },
  SearchItem_Divider: {
    margin: '0 16px',
  },
  SearchItem_GridContainer: {
    padding: '16px 16px 0 16px',
  },
  Search_LeftSideContainer: {
    paddingLeft: 16,
    flex: '1 0 100px',
    maxWidth: 'calc(100% - 64px)',
  },
  SearchItem_CoinWrapper: {
    display: 'inline-flex',
  },
  SearchItem_LockContainer: {
    position: 'absolute',
  },
  SearchItem_LockIcon: {
    position: 'relative',
    top: '24px',
    left: '24px',
    color: theme.palette.disabled.main,
    fontSize: 24,
    zIndex: 2,
  },
  IconContainer: {
    flex: '0 1 64px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const SearchItem = ({
  isCourse = false,
  idCourse = '',
  courseName = '',
  title = '',
  iconSrc = '',
  divider = false,
  id = 0,
  specialResult,
  onBackdropSet,
  onSearchValueSet,
  isMobile,
  pricing,
  courseIsFree,
  userIsEnrolled,
  noNavHandler,
}) => {
  const c = useStyles()
  const history = useHistory()
  const intl = useIntl()
  const theme = useTheme()

  const handleItemButton = () => {
    if (isCourse) {
      if (userIsEnrolled || pricing?.regularPrice > 0) {
        onSearchValueSet('')
        onBackdropSet(false)
      }
      userIsEnrolled
        ? history.push(`/course/${id}`)
        : pricing?.regularPrice > 0
          ? history.push(`/shop?course=${id}`)
        : noNavHandler()
    } else {
      onSearchValueSet('')
      onBackdropSet(false)

      userIsEnrolled
        ? history.push(`/lesson/${idCourse}/${id}`)
        : history.push(`/shop?course=${idCourse}`)
    }
  }

  const isBlock = typeof specialResult !== 'string'

  return specialResult ? (
    <Box
      className={clsx(c.SearchItem_MainContainer, c.SearchItem_MainContainer__NotFound)}
    >
      <Grid container wrap='nowrap' justifyContent='center'>
        <Grid
          item
          lg={12}
          className={clsx(isBlock && c.SearchItem_TitleWrapper__Centered)}
        >
          <Typography
            className={clsx(
              c.SearchItem_Title,
              c.SearchItem__OneLine,
              isBlock && c.SearchItem__Centered
            )}
            component={isBlock ? 'div' : 'p'}
          >
            {specialResult}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box className={c.SearchItem_MainContainer}>
      <CardActionArea onClick={handleItemButton}>
        <Grid container wrap='nowrap' className={c.SearchItem_GridContainer}>
          {isCourse && (
            <Grid className={c.IconContainer} item container>
              <Avatar
                className={c.SearchItem_Icon}
                src={iconSrc}
                color={
                  courseIsFree
                    ? theme.palette.secondary.variant1
                    : userIsEnrolled
                    ? theme.palette.primary.main
                      : theme.palette.premium.main
                }
                size={isMobile ? 50 : 64}
                round={true}
                title={title}
                name={title.replace(/[^a-zA-Z\s]+/g, '')}
                maxInitials={2}
              ></Avatar>
              {!userIsEnrolled && (
                <div className={c.SearchItem_LockContainer}>
                  <LockIcon className={c.SearchItem_LockIcon}></LockIcon>
                </div>
              )}
            </Grid>
          )}
          <Grid item className={c.Search_LeftSideContainer}>
            <Typography className={c.SearchItem_TypeOfCard} component='p'>
              {!isCourse
                ? intl.formatMessage({ id: 'shared.lesson' })
                : courseIsFree
                  ? intl.formatMessage({ id: 'shared.freeCourse' })
                  : userIsEnrolled
                ? intl.formatMessage({ id: 'shared.myCourse' })
                : intl.formatMessage({ id: 'shared.paidCourse' })}
            </Typography>
            <Typography
              className={clsx(c.SearchItem_Title, c.SearchItem__OneLine)}
              component='p'
            >
              {title}
            </Typography>
            {!isCourse && (
              <Typography className={c.SearchItem_CourseName}>{courseName}</Typography>
            )}
            <Grid
              className={c.SearchItem_ButtonContainer}
              item
              container
              justifyContent='space-between'
              alignItems='center'
              direction='row'
              wrap='nowrap'
            >
              <Grid item container justifyContent='flex-start' alignItems='center'>
                <Typography className={c.SearchItem_Button} color='primary'>
                  <FormattedMessage
                    id={courseIsFree || userIsEnrolled ? 'shared.view' : 'shared.buy'}
                  />
                </Typography>
              </Grid>
              {!courseIsFree && !userIsEnrolled && pricing && pricing.regularPrice > 0 && (
                <div className={c.SearchItem_CoinWrapper}>
                  <PriceInCoins price={pricing?.regularPrice}></PriceInCoins>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        {divider && <Divider className={c.SearchItem_Divider} />}
      </CardActionArea>
    </Box>
  );
}

export default SearchItem
