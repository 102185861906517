import { Backdrop, Grid, Paper } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import IframeResizer from 'iframe-resizer-react'
import { makeStyles } from '@mui/styles'

import React from 'react'

const useStyles = makeStyles(theme => ({
  RegisterPopUp_CardC: {
    margin: 'auto',
    height: 'min(700px, 100%)',
    width: '80%',
    [theme.breakpoints.down(1000)]: {
      height: 'min(800px, 100%)',
    },
    [theme.breakpoints.down(500)]: {
      height: `calc(100% - ${theme.spacing(2)} )`,
      width: `calc(100% - ${theme.spacing(2)} )`,
    },
  },
  RegisterPopUp_Card: {
    height: 'calc(100% - 40px)',
    width: '100%',
    borderRadius: '8px',
    padding: theme.spacing(1),
  },
  RegisterPopUp_Iframe: {
    width: '100%',
    height: 'calc(100%)',
    borderRadius: '8px',
    overflow: 'scroll',
    border: 'none',
  },
  RegisterPopUp_CloseIcon: {
    height: '32px',
    width: '32px',
    marginBottom: theme.spacing(1),
    color: 'white',
  },
}))

const RegisterPopUp = ({ open, handleClose }) => {
  const c = useStyles()
  return (
    <Backdrop
      style={{ zIndex: 2, height: '100vh', width: '100vw' }}
      open={open}
      onClick={e => e.preventDefault()}
    >
      <Grid container className={c.RegisterPopUp_CardC}>
        <Grid container justifyContent='flex-end'>
          <CloseIcon
            className={c.RegisterPopUp_CloseIcon}
            onClick={handleClose}
          ></CloseIcon>
        </Grid>
        <Paper className={c.RegisterPopUp_Card}>
          {open && (
            <IframeResizer
              className={c.RegisterPopUp_Iframe}
              src='https://kurse.plakos-akademie.de/registrierung-neu/'
              heightCalculationMethod='lowestElement'
              scrolling={true}
            ></IframeResizer>
          )}
        </Paper>
      </Grid>
    </Backdrop>
  );
}

export default RegisterPopUp
