import store from 'app/store'
import { sendPageView } from './slice'
//Base Title of the Website. Don't use intl on this, cause it is also the key for tracking in GA4
const PAGE_BASE_TITLE = 'Plakos Akademie'

export function setPageTitle(type, title = '') {
  let pageTitle = `${PAGE_BASE_TITLE} | ${resolveType(type)}${title ? ' - ' + title : ''}` // Plakos Akademie

  document.title = pageTitle
  store.dispatch(sendPageView(pageTitle))
}

/**
 * Simple Mapping of the type of page in order to get the Correct page title.
 * @param {*} type
 * @returns
 */
function resolveType(type) {
  switch (type) {
    case PAGE_TITLE_TYPES.COURSE:
      return 'Kurs'
    case PAGE_TITLE_TYPES.LESSON:
      return 'Lektion'
    case PAGE_TITLE_TYPES.TOPIC:
      return 'Thema'
    case PAGE_TITLE_TYPES.TEST:
      return 'Test'
    case PAGE_TITLE_TYPES.PROFILE:
      return 'Profil'
    case PAGE_TITLE_TYPES.LOGIN:
      return 'Login - Web/App Anmeldung'
    case PAGE_TITLE_TYPES.SHOP:
      return 'Endecke neue Kurse'
    case PAGE_TITLE_TYPES.DASHBOARD:
      return 'Startseite'
    case PAGE_TITLE_TYPES.NOTEPAD:
      return 'Merkzettel'
    case PAGE_TITLE_TYPES.BETA:
      return 'Offene Beta'
    default:
      return ''
  }
}

export const PAGE_TITLE_TYPES = {
  COURSE: 'course',
  LESSON: 'lesson',
  TOPIC: 'topic',
  TEST: 'test',
  PROFILE: 'profile',
  LOGIN: 'login',
  SHOP: 'shop',
  DASHBOARD: 'dashboard',
  NOTEPAD: 'notepad',
  BETA: 'beta',
}
