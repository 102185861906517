import { getAuthToken, clearCurrentUser } from '@services/currentUser'
import { lang } from '@services/intl'
import axios from 'axios'

let controller

export default ({
  method = 'GET',
  endpoint,
  body,
  cancelToken,
  timeout = 300000,
  params,
  areaPath = '/memberarea',
}) => {
  if (endpoint === '/user/courses') {
    if (controller) {
      controller.abort()
    }
  }
  if (endpoint === '/user/courses') {
    controller = new AbortController()
  }
  const authToken = getAuthToken()
  return axios({
    url: `${process.env.REACT_APP_API_URL}${areaPath}${endpoint}`,
    method,
    cancelToken,
    timeout,
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': lang,
      Tag: process.env.REACT_APP_PRODUCT_TAG,
      ...(authToken && { Authorization: `Bearer ${authToken}` }),
    },
    signal: controller && endpoint === '/user/courses' ? controller.signal : null,
    ...(body && { data: JSON.stringify(body) }),
    ...(params && { params }),
  }).catch(e => {
    if (e && e.response && e.response.status === 403) {
      clearCurrentUser()
      window.location.href = '/login'
    }

    return Promise.reject(e)
  })
}
