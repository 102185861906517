import { STATUSES } from '@constants/slices'
import { createSlice } from '@reduxjs/toolkit'
import api from '@services/api'

export const notepadSlice = createSlice({
    name: 'notepad',
    initialState: {
        value: {},
        status: STATUSES.INIT,
    },
    reducers: {
        loaded: (state, { payload }) => ({
            ...state,
            value: payload,
            status: STATUSES.LOADED,
        }),
        loading: state => ({...state, status: STATUSES.LOADING }),
        error: state => ({...state, status: STATUSES.ERROR }),
    },
})

export const getNotepad = id => dispatch => {
    dispatch(loading())
    api({ endpoint: `/notepad/${id}` })
        .then(res => {
            const notepad = res.data
            notepad.type = 'notepad'
            dispatch(loaded(notepad))
        })
        .catch(() => dispatch(error()))
}

export const selectNotepad = state => state.notepad.value
export const selectNotepadStatus = state => state.notepad.status

export const { loaded, loading, error } = notepadSlice.actions
export default notepadSlice.reducer