import { configureStore } from '@reduxjs/toolkit'
import dashboardReducer from '@pages/Dashboard/slice'
import courseReducer from '@pages/Course/slice'
import lessonReducer from '@pages/Lesson/slice'
import topicReducer from '@pages/Topic/slice'
import testReducer from '@pages/Test/slice'
import profileReducer from '@pages/Profile/slice'
import loginReducer from '@pages/Login/slice'
import notepadReducer from '@pages/Notepad/slice'
import courseOverviewReducer from '@services/courseOverview/slice'
import shopReducer from '@pages/Shop/slice'
import premiumOfferReducer from '@services/premiumOffer/slice'
import gaIntegrationReducer from '@services/gaIntegration/slice'

export default configureStore({
  reducer: {
    dashboard: dashboardReducer,
    course: courseReducer,
    lesson: lessonReducer,
    topic: topicReducer,
    test: testReducer,
    profile: profileReducer,
    login: loginReducer,
    notepad: notepadReducer,
    courseOverview: courseOverviewReducer,
    shop: shopReducer,
    premiumOffer: premiumOfferReducer,
    gaIntegration: gaIntegrationReducer,
  },
})
