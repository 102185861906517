import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getDashboard,
  selectDashboard,
  selectDashboardStatus,
  getDashboardSilent,
} from './slice'

import CardCourses from '@components/molecules/CardCourses'

import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { FormattedMessage, useIntl } from 'react-intl'
import CardWithImage from '@components/molecules/СardWithImage'
import { STATUSES } from '@constants/slices'
import AssesmentCountDown from '@components/molecules/AssesmentCountDown'
import betaImage from '@assets/practiceMode.png'
import betaImageB from '@assets/practiceModeb.svg'
import { emitter } from '@marvelapp/react-ab-test'

import { PAGE_TITLE_TYPES, setPageTitle } from '@services/gaIntegration'
import { selectGAStatus } from '@services/gaIntegration/slice'

export const BETA_ENABELED = true

const myStyles = makeStyles(theme => ({
  DashBoard: {
    padding: `0px ${theme.spacing(6)}`,
    [theme.breakpoints.down(600)]: {
      padding: `0px ${theme.spacing(2)}`,
      flexDirection: 'column',
    },
  },
  DashBoard_Header: {
    [theme.breakpoints.down(900)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  DashBoard_CoursesWrapper: {
    paddingRight: '16px',
    [theme.breakpoints.down(960)]: {
      paddingRight: '0',
    },
  },
  Card_Paper__blank: {
    padding: 15,
    backgroundColor: '#fff',
    height: 200,
    marginBottom: theme.spacing(2),
  },
  DashBoard_MainTitle: {
    fontSize: '48px',
    lineHeight: '63px',
    padding: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down(550)]: {
      fontSize: '32px',
      padding: `${theme.spacing(1)} 0`,
      lineHeight: '48px',
    },
  },
  DashBoard_SubTitle: {
    fontSize: '19px',
    lineHeight: '26px',
    padding: 0,
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down(550)]: {
      fontSize: '16px',
      lineHeight: '24px',
      padding: `${theme.spacing(1)} 0`,
    },
  },
}))

const resolveBetaImageByType = () => {
  return emitter.getActiveVariant('ThemeTest') === 'Plakos' ? betaImage : betaImageB
}

const textForLastTest = dashboard => {
  if (dashboard.last_test) {
    const { surroundingCourse, surroundingLesson, surroundingTopic } = dashboard.last_test
    return [surroundingCourse, surroundingLesson, surroundingTopic]
      .map(item => item?.title)
      .filter(item => !!item)
      .join(' - ')
  }
  return ''
}

const textForLastTopic = dashboard => {
  if (dashboard.last_topic) {
    const { surroundingCourse, surroundingLesson } = dashboard.last_topic

    return [surroundingCourse, surroundingLesson]
      .map(item => item?.title)
      .filter(item => !!item)
      .join(' - ')
  }
  return ''
}

const DashBoard = () => {
  const dispatch = useDispatch()
  const c = myStyles()
  const intl = useIntl()
  const dashboard = useSelector(selectDashboard)
  const dashboardStatus = useSelector(selectDashboardStatus)
  const [status, setStatus] = useState(STATUSES.INIT)
  const isActive = useSelector(selectGAStatus)

  useEffect(() => {
    setPageTitle(PAGE_TITLE_TYPES.DASHBOARD)
  }, [isActive])

  useEffect(() => {
    if (dashboardStatus === STATUSES.LOADED) {
      dispatch(getDashboardSilent())
    } else {
      dispatch(getDashboard())
    }
  }, [dispatch])

  useEffect(() => {
    if ('courses' in dashboard && dashboard.courses) {
      setStatus(STATUSES.LOADED)
    } else {
      setStatus(dashboardStatus)
    }
  }, [dashboardStatus, dashboard])

  const { last_test, last_topic } = dashboard

  const courses = dashboard?.courses?.filter(
    c =>
      (c.userIsEnrolled && !c.courseIsFree) ||
      (c.courseIsFree && c.userIsSubscribedToFree)
  )

  return (
    <Box className={c.DashBoard}>
      <Grid
        item
        container
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
        className={c.DashBoard_Header}
      >
        <Grid item>
          <Typography variant='h3' className={c.DashBoard_MainTitle}>
            <FormattedMessage id='shared.welcome' />
          </Typography>
        </Grid>
        <Grid>
          <AssesmentCountDown></AssesmentCountDown>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item container md={12} xs={12} direction='row'>
          <Grid item md={9} xs={12} className={c.DashBoard_CoursesWrapper}>
            <CardCourses data={{ courses: courses }} status={status} />
          </Grid>
          <Grid item md={3} xs={12}>
            {last_test && (
              <CardWithImage
                imgUrl={last_test?.postImage}
                typeOfCard={intl.formatMessage({ id: 'shared.previousTest' })}
                title={last_test?.title}
                text={textForLastTest(dashboard)}
                buttonText={intl.formatMessage({ id: 'shared.start' })}
                progressBar={last_test?.progress}
                status={status}
                url={`/test/${last_test?.surroundingCourse?.id}/${last_test?.id}`}
              />
            )}
            {last_topic && (
              <CardWithImage
                imgUrl={last_topic?.postImage}
                typeOfCard={intl.formatMessage({ id: 'shared.previousTopic' })}
                title={last_topic?.title}
                text={textForLastTopic(dashboard)}
                buttonText={intl.formatMessage({ id: 'shared.view' })}
                status={status}
                url={`/topic/${last_topic?.surroundingCourse?.id}/${last_topic?.id}`}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DashBoard
