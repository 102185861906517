import {
  Box,
  Button,
  Collapse,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { makeStyles, styled } from '@mui/styles'
import React from 'react'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import clsx from 'clsx'
import { CookieDetailRow } from './CookieDetailRow'
import { Link, useHistory } from 'react-router-dom'

export const YEAR_IN_MS = 31556926000
export const COOKIE_CONSENT_COOKIE_NAME = 'PlakosCookieConsent'

const useStyles = makeStyles(theme => ({
  CookieBanner: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    minHeight: '72px',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.cookie,
    display: 'flex',
    alignItems: 'center',
  },
  CookieBanner_c: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.down(1100)]: {
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  CookieBanner_info: {
    color: 'white',
    [theme.breakpoints.down(1100)]: {
      textAlign: 'center',
    },
  },
  CookieBanner_settingsCard: {
    maxWidth: 'max(300px, 60vw)',
    padding: theme.spacing(2),
    [theme.breakpoints.down(420)]: {
      maxWidth: '100%',
      padding: theme.spacing(1),
    },
  },
  CookieBanner_inactive: {
    opacity: 0.5,
  },
  CookieBanner_detailTitle: {
    fontSize: 20,
    color: theme.palette.primary.main,
    [theme.breakpoints.down(550)]: {
      fontSize: 18,
    },
  },
  CookieBanner_detailInfo: {
    fontSize: 16,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(550)]: {
      fontSize: 14,
    },
  },
  CookieBanner_buttonText: {
    color: theme.palette.secondary.variant3,
  },
  CookieBanner_expandButton: {
    fontSize: 14,
  },
}))

export const CookieBanner = ({ onUpdate }) => {
  const c = useStyles()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [gaAccepted, setGaAccepted] = useState(false)
  const [chatAccepted, setChatAccepted] = useState(false)
  const intl = useIntl()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(550))

  const setCookie = consentData => {
    const expires = new Date(Date.now() + YEAR_IN_MS)
    document.cookie = `${COOKIE_CONSENT_COOKIE_NAME}=${JSON.stringify(
      consentData
    )}; expires=${expires}; path=/`
    onUpdate()
  }

  const createConsentData = (ga, chat) => {
    return {
      tracking: ga,
      chat: chat,
    }
  }

  const accept = () => {
    setCookie(createConsentData(true, true))
  }

  const saveAndAccept = () => {
    setCookie(createConsentData(gaAccepted, chatAccepted))
    setOpen(false)
  }

  return (
    <>
      <Box className={clsx(c.CookieBanner, open ? c.CookieBanner_inactive : null)}>
        <Grid container className={c.CookieBanner_c} height='100%'>
          <Grid item flexBasis='100' flexShrink='1'>
            <Typography className={c.CookieBanner_info}>
              <FormattedMessage
                id='cookieBanner.info'
                values={{
                  privacy: (
                    <a
                      href='https://plakos-akademie.de/datenschutz/'
                      target='_blank'
                      rel='noreferrer'
                      style={{ color: theme.palette.secondary.variant3 }}
                    >
                      {intl.formatMessage({ id: 'footer.privacyPolicy' })}
                    </a>
                  ),
                }}
              ></FormattedMessage>
            </Typography>
          </Grid>
          <Grid item flexBasis='350'>
            <Button onClick={accept}>
              <Typography className={c.CookieBanner_buttonText}>
                <FormattedMessage id='cookieBanner.accept'></FormattedMessage>
              </Typography>
            </Button>
            <Button onClick={() => setOpen(true)}>
              <Typography className={c.CookieBanner_buttonText}>
                <FormattedMessage id='cookieBanner.openSettings'></FormattedMessage>
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
        }}
      >
        <Paper className={c.CookieBanner_settingsCard}>
          <Typography className={c.CookieBanner_detailTitle} component='h4'>
            <FormattedMessage id='cookieBanner.detailTitle'></FormattedMessage>
          </Typography>
          <Collapse in={expanded} collapsedSize={isMobile ? '42px' : '48px'}>
            <Typography className={c.CookieBanner_detailInfo}>
              <FormattedMessage
                id='cookieBanner.detailInfo'
                values={{
                  cookieSettings: (
                    <Link
                      onClick={() => {
                        if (open) setOpen(false)
                        history.push('/cookies')
                      }}
                      variant='standard'
                    >
                      {intl.formatMessage({ id: 'shared.myProfile' })}
                    </Link>
                  ),
                  privacy: (
                    <a
                      href='https://plakos-akademie.de/datenschutz/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      {intl.formatMessage({ id: 'footer.privacyPolicy' })}
                    </a>
                  ),
                }}
              ></FormattedMessage>
            </Typography>
          </Collapse>
          <Button
            onClick={() => setExpanded(!expanded)}
            aria-expanded={expanded}
            aria-label='show more'
            style={{ paddingLeft: '0px' }}
          >
            <Typography className={c.CookieBanner_expandButton}>
              <FormattedMessage
                id={expanded ? 'shared.collapse' : 'shared.expand'}
              ></FormattedMessage>
            </Typography>
          </Button>
          <Grid container directon='column' justifyContent='center'>
            <CookieDetailRow
              info={intl.formatMessage({ id: 'cookieBanner.description' })}
              time={intl.formatMessage({ id: 'cookieBanner.saveTime' })}
            ></CookieDetailRow>
            <CookieDetailRow
              info={intl.formatMessage({ id: 'cookieBanner.neccassaryInfo' })}
              time={intl.formatMessage({ id: 'cookieBanner.neccassaryTime' })}
            ></CookieDetailRow>
            <CookieDetailRow
              checked={gaAccepted}
              setChecked={v => setGaAccepted(v)}
              info={intl.formatMessage({ id: 'cookieBanner.trackingInfo' })}
              time={intl.formatMessage({ id: 'cookieBanner.trackingTime' })}
            ></CookieDetailRow>
            <CookieDetailRow
              checked={chatAccepted}
              setChecked={v => setChatAccepted(v)}
              info={intl.formatMessage({ id: 'cookieBanner.chatInfo' })}
              time={intl.formatMessage({ id: 'cookieBanner.chatTime' })}
            ></CookieDetailRow>
            <Grid container item justifyContent='end'>
              <Button onClick={saveAndAccept}>
                <Typography>
                  <FormattedMessage id='cookieBanner.saveAndAccept'></FormattedMessage>
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </>
  )
}
