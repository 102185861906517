import { Grid, Link, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  Footer_Background: {
    width: '100%',
    bottom: 0,
    alignSelf: 'bottom',
  },
  Footer_BackgroundTransparent: {
    backgroundColor: theme.palette.background.colored,
  },
  Footer_BackgroundFull: {
    background: `${theme.palette.background.default} 0% 0% no-repeat padding-box`,
  },
  Footer_Container: {
    padding: theme.spacing(4),
  },
  Footer_Item: {},
  Footer_Link: {
    textAlign: 'center',
  },
}))

const Footer = () => {
  const styles = useStyles()
  const history = useHistory()
  return (
    <Paper
      square
      elevation={0}
      className={clsx(
        styles.Footer_Background,
        history?.location?.pathname !== '/login'
          ? styles.Footer_BackgroundTransparent
          : styles.Footer_BackgroundFull
      )}
      id='footer'
    >
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='center'
        className={styles.Footer_Container}
      >
        <Grid container item md={1} justifyContent='center'>
          <Link
            className={styles.Footer_Link}
            href='https://plakos-akademie.de/'
            target='_blank'
            underline='hover'
          >
            <FormattedMessage id='footer.company'></FormattedMessage>
          </Link>
        </Grid>
        <Grid container item md={1} justifyContent='center'>
          <Link
            className={styles.Footer_Link}
            href='https://plakos-akademie.de/impressum'
            target='_blank'
            underline='hover'
          >
            <FormattedMessage id='login.impressum'></FormattedMessage>
          </Link>
        </Grid>
        <Grid container item md={1} justifyContent='center'>
          <Link
            className={styles.Footer_Link}
            href='https://plakos-akademie.de/datenschutz/'
            target='_blank'
            underline='hover'
          >
            <FormattedMessage id='footer.privacyPolicy'></FormattedMessage>
          </Link>
        </Grid>
        <Grid container item md={1} justifyContent='center'>
          <Link
            className={styles.Footer_Link}
            href='https://plakos-akademie.de/agb/'
            target='_blank'
            underline='hover'
          >
            <FormattedMessage id='footer.termsOfUse'></FormattedMessage>
          </Link>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default Footer
