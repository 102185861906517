import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => {
  return {
    Spacer: {
      width: ({ size, width }) => size || width || '100%',
      height: ({ size, height }) => size || height || '100%',
      flex: 'none',
      [theme.breakpoints.down(1280)]: {
        width: ({ mobileSize, size, width }) => mobileSize || size || width || '100%',
        height: ({ mobileSize, size, height }) => mobileSize || size || height || '100%',
      },
    },
  }
})

const Spacer = ({ className, size, width, height, mobileSize }) => {
  const c = useStyles({ size, width, height, mobileSize })
  return <div className={clsx(className, c.Spacer)}></div>
}

export default Spacer
