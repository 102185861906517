export const getCurrentUser = () => {
  const currentUserStr = localStorage.getItem('currentUser')
  if (currentUserStr) {
    try {
      return JSON.parse(currentUserStr)
    } catch (e) {
      clearCurrentUser()
    }
  }
}

export const setCurrentUser = userData => {
  localStorage.setItem('currentUser', JSON.stringify(userData))
}

export const clearCurrentUser = () => {
  localStorage.removeItem('currentUser')
  localStorage.removeItem('authToken')
}

export const setAuthToken = token => {
  localStorage.setItem('authToken', token)
}

export const getAuthToken = () => localStorage.getItem('authToken')
