import React, { useContext, useEffect, useState } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'

import AppContext from './context/index'

import Community from '@pages/Сommunity'
import Dashboard, { BETA_ENABELED } from '@pages/Dashboard'
import MobileSearch from '@pages/MobileSearch'
import Course from '@pages/Course'
import Lesson from '@pages/Lesson'
import Test from '@pages/Test'
import Topic from '@pages/Topic'
import Impressum from '@pages/Impressum'
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from '@mui/material/styles'

import Header from '@components/molecules/Header'

import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import theme from './theme'
import themeB from './themeB'
import ProfileLayout from '@components/organisms/ProfileLayout'
import Settings from '@pages/Profile/Settings'
import Login from '@pages/Login'
import { getCurrentUser } from '@services/currentUser'
import Notepad from '../@pages/Notepad'
import ScrollToTop from '../@components/atoms/ScrollToTop'
import Footer from '@components/molecules/Footer'
import { initChat } from '@services/chatIntegration'
import Shop from '@pages/Shop'
import { useMediaQuery } from '@mui/material'
import MobileHeader from '@components/molecules/MobileHeader'
import { CookieBanner, COOKIE_CONSENT_COOKIE_NAME } from '@components/atoms/CookieBanner'
import { CookieSettings } from '@pages/Profile/CookieSettings'
import PracticeMode from '@pages/Beta'
import { activateGA, deactivateGA } from '@services/gaIntegration/slice'
import { useDispatch } from 'react-redux'
import {
  Experiment,
  Variant,
  experimentDebugger,
  emitter,
} from '@marvelapp/react-ab-test'

const PrivateRoute = ({ children, ...props }) => {
  const currentUser = getCurrentUser()

  return (
    <Route
      {...props}
      render={({ location }) =>
        currentUser ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export const getThemeByVariant = variantName => {
  return variantName === 'Plakos' ? extendTheme(theme) : extendTheme(themeB)
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const App = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down(900))
  let query = useQuery()
  const dispatch = useDispatch()

  emitter.defineVariants('ThemeTest', ['Plakos', 'Apelio'])

  const { appState, setAppState } = useContext(AppContext)
  const [consentUpdated, setConsentUpdated] = useState(false)
  const [consentRequestable, setConsentRequestable] = useState(false)
  const currentUser = getCurrentUser()

  useEffect(() => {
    const cookies = document.cookie?.split(';')
    let consentCookie = cookies.find(c => c.includes(COOKIE_CONSENT_COOKIE_NAME))
    if (!consentCookie) {
      setConsentRequestable(true)
    } else {
      setConsentRequestable(false)
    }
  }, [consentUpdated])

  useEffect(() => {
    if (currentUser) {
      const cookies = document.cookie?.split(';')
      let consentCookie = cookies.find(c => c.includes(COOKIE_CONSENT_COOKIE_NAME))
      if (consentCookie) {
        let consentData
        try {
          consentData = JSON.parse(consentCookie.split('=')[1])
        } catch {
          console.log('Cookie parsing failed')
        }
        if (consentData) {
          try {
            initChat(consentData.chat)
          } catch {
            console.log('init chat failed')
          }
        }
      }
    }
  }, [currentUser, consentUpdated])

  useEffect(() => {
    const cookies = document.cookie?.split(';')
    let consentCookie = cookies.find(c => c.includes(COOKIE_CONSENT_COOKIE_NAME))
    if (consentCookie) {
      let consentData
      try {
        consentData = JSON.parse(consentCookie.split('=')[1])
      } catch {
        console.log('Cookie parsing failed')
      }
      if (consentData) {
        if (consentData.tracking) {
          dispatch(activateGA())
        } else {
          dispatch(deactivateGA())
        }
      }
    }
  }, [consentUpdated])

  useEffect(() => {
    appState.isReady = true
    setAppState(appState)
  })

  return (
    <>
      <Experiment name='ThemeTest'>
        <Variant name='Plakos'>
          <CssVarsProvider theme={getThemeByVariant('Plakos')}>
            <CssBaseline />
            {isMobile ? <MobileHeader></MobileHeader> : <Header />}
            <main style={{ minHeight: '100%' }}>
              <ScrollToTop>
                <Switch>
                  <Route exact path='/login'>
                    <Login />
                  </Route>
                  <Route exact path='/impressum'>
                    <Impressum />
                  </Route>
                  <PrivateRoute exact path='/'>
                    <Dashboard />
                  </PrivateRoute>
                  <Redirect from='/dashboard' to='/' />
                  <PrivateRoute exact path='/community'>
                    <Community />
                  </PrivateRoute>
                  <PrivateRoute exact path='/search'>
                    <MobileSearch />
                  </PrivateRoute>
                  <PrivateRoute exact path='/course/:courseId'>
                    <Course />
                  </PrivateRoute>
                  <PrivateRoute exact path='/lesson/:courseId/:lessonId'>
                    <Lesson />
                  </PrivateRoute>
                  <PrivateRoute exact path='/test/:courseId/:testId'>
                    <Test />
                  </PrivateRoute>
                  <PrivateRoute exact path='/topic/:courseId/:topicId'>
                    <Topic />
                  </PrivateRoute>
                  <PrivateRoute exact path='/notepad/:courseId'>
                    <Notepad />
                  </PrivateRoute>
                  <PrivateRoute exact path='/shop'>
                    <Shop openWithCourse={query.get('course')} />
                  </PrivateRoute>
                  <ProfileLayout>
                    <Switch>
                      <PrivateRoute exact path='/profile'>
                        <Settings />
                      </PrivateRoute>
                      <PrivateRoute exact path='/cookies'>
                        <CookieSettings
                          onUpdate={() => setConsentUpdated(!consentUpdated)}
                        />
                      </PrivateRoute>
                      <Redirect from='*' to='/dashboard' />
                    </Switch>
                  </ProfileLayout>
                </Switch>
              </ScrollToTop>
            </main>
            {consentRequestable && (
              <CookieBanner
                onUpdate={() => setConsentUpdated(!consentUpdated)}
              ></CookieBanner>
            )}
            <Footer></Footer>
          </CssVarsProvider>
        </Variant>
        <Variant name='Apelio'>
          <CssVarsProvider theme={getThemeByVariant('Apelio')}>
            <CssBaseline />
            {isMobile ? <MobileHeader></MobileHeader> : <Header />}
            <main style={{ minHeight: '100%' }}>
              <ScrollToTop>
                <Switch>
                  <Route exact path='/login'>
                    <Login />
                  </Route>
                  <Route exact path='/impressum'>
                    <Impressum />
                  </Route>
                  <PrivateRoute exact path='/'>
                    <Dashboard />
                  </PrivateRoute>
                  <Redirect from='/dashboard' to='/' />
                  <PrivateRoute exact path='/community'>
                    <Community />
                  </PrivateRoute>
                  <PrivateRoute exact path='/search'>
                    <MobileSearch />
                  </PrivateRoute>
                  <PrivateRoute exact path='/course/:courseId'>
                    <Course />
                  </PrivateRoute>
                  <PrivateRoute exact path='/lesson/:courseId/:lessonId'>
                    <Lesson />
                  </PrivateRoute>
                  <PrivateRoute exact path='/test/:courseId/:testId'>
                    <Test />
                  </PrivateRoute>
                  <PrivateRoute exact path='/topic/:courseId/:topicId'>
                    <Topic />
                  </PrivateRoute>
                  <PrivateRoute exact path='/notepad/:courseId'>
                    <Notepad />
                  </PrivateRoute>
                  <PrivateRoute exact path='/shop'>
                    <Shop openWithCourse={query.get('course')} />
                  </PrivateRoute>
                  {BETA_ENABELED && (
                    <PrivateRoute exact path='/practiceMode'>
                      <PracticeMode></PracticeMode>
                    </PrivateRoute>
                  )}
                  <ProfileLayout>
                    <Switch>
                      <PrivateRoute exact path='/profile'>
                        <Settings />
                      </PrivateRoute>
                      <PrivateRoute exact path='/cookies'>
                        <CookieSettings
                          onUpdate={() => setConsentUpdated(!consentUpdated)}
                        />
                      </PrivateRoute>
                      <Redirect from='*' to='/dashboard' />
                    </Switch>
                  </ProfileLayout>
                </Switch>
              </ScrollToTop>
            </main>
            {consentRequestable && (
              <CookieBanner
                onUpdate={() => setConsentUpdated(!consentUpdated)}
              ></CookieBanner>
            )}
            <Footer></Footer>
          </CssVarsProvider>
        </Variant>
      </Experiment>
    </>
  )
}
export default App
