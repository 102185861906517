import FENIntegration from '@components/atoms/FENIntegration'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { PAGE_TITLE_TYPES, setPageTitle } from '@services/gaIntegration'
import { selectGAStatus } from '@services/gaIntegration/slice'
import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  PracticeMode_Header: {
    backgroundColor: theme.palette.background.colored,
    minHeight: '128px',
    padding: `${theme.spacing(1)} 15%`,
    [theme.breakpoints.down(768)]: {
      minHeight: '64px',
    },
  },
  PracticeMode_ContentContainer: {
    padding: `0px ${theme.spacing(12)}`,
    margin: `${theme.spacing(2)} ${theme.spacing(10)}`,
    [theme.breakpoints.down(1024)]: {
      padding: `0px ${theme.spacing(8)}`,
      margin: `${theme.spacing(2)} ${theme.spacing(6)}`,
    },
    [theme.breakpoints.down(768)]: {
      padding: `0px ${theme.spacing(6)}`,
      margin: `${theme.spacing(2)} ${theme.spacing(2)}`,
    },
  },
  PracticeMode_Title: {
    color: theme.palette.primary.variant2,
    textAlign: 'center',
    fontSize: '48px',
    lineHeight: '63px',
    [theme.breakpoints.down(768)]: {
      fontSize: '30px',
      lineHeight: '40px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '20px',
      lineHeight: '26px',
    },
  },
  PracticeMode_Text: {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down(600)]: {
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
}))

export default function PracticeMode() {
  const c = useStyles()
  const isActive = useSelector(selectGAStatus)

  useEffect(() => {
    setPageTitle(PAGE_TITLE_TYPES.BETA)
  }, [isActive])

  return (
    <Grid container className={c.PracticeMode}>
      <Grid
        container
        className={c.PracticeMode_Header}
        justifyContent='center'
        alignItems='center'
        direction='column'
      >
        <Typography className={c.PracticeMode_Title} component='h2'>
          <FormattedMessage id='beta.beta'></FormattedMessage>
        </Typography>
        <Typography className={c.PracticeMode_Text} component='p'>
          <FormattedMessage id='beta.description_p1'></FormattedMessage>
        </Typography>
        <Typography className={c.PracticeMode_Text} component='p'>
          <FormattedMessage id='beta.description_p2'></FormattedMessage>
        </Typography>
        <Typography className={c.PracticeMode_Text} component='p'>
          <FormattedMessage id='beta.description_p3'></FormattedMessage>
        </Typography>
      </Grid>
      <Grid item className={c.PracticeMode_ContentContainer} sm={12} xs={12} md={12}>
        <FENIntegration
          testLink={
            'https://testtrainer.nice-wing.18-185-250-110.plesk.page/practice/?id=1069833&call=learndash&skip_cache=1'
          }
        ></FENIntegration>
      </Grid>
    </Grid>
  )
}
