import { createSlice } from '@reduxjs/toolkit'
import api from '@services/api'
import { STATUSES } from '@constants/slices'
import { format, isMatch } from 'date-fns'

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    value: {},
    status: STATUSES.INIT,
  },
  reducers: {
    loaded: (_, { payload }) => ({ value: payload, status: STATUSES.LOADED }),
    updateCoins: (state, { payload }) => ({
      ...state,
      value: { ...state.value, userCoins: payload },
    }),
    loading: state => ({ ...state, status: STATUSES.LOADING }),
    error: state => ({ ...state, status: STATUSES.ERROR }),
  },
})

export const getProfile = () => dispatch => {
  dispatch(loading())
  api({ endpoint: '/user/profile' })
    .then(res => {
      dispatch(loaded(res.data))
    })
    .catch(() => dispatch(error()))
}

export const postProfile = data => dispatch => {
  dispatch(loading())
  api({
    endpoint: '/user/profile',
    method: 'POST',
    body: {
      ...data,
      assessmentDate:
        isMatch(data?.assessmentDate, 'dd.MM.yyyy') || !data?.assessmentDate
          ? data?.assessmentDate
          : format(data?.assessmentDate, 'dd.MM.yyyy'),
    },
  })
    .then(() => {
      dispatch(getProfile())
    })
    // eslint-disable-next-line no-console
    .catch(console.log)
}

export const updateUserCoins = coinValue => dispatch => {
  dispatch(updateCoins(coinValue))
}

export const selectProfile = state => state.profile
export const selectUserCoins = state => state.profile?.value?.userCoins

export const { loaded, loading, error, updateCoins } = profileSlice.actions
export default profileSlice.reducer
