import React, { useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import Object2 from '@assets/test.glb'
import { makeStyles } from '@mui/styles'
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation'
import { Tooltip } from '@mui/material'
import { useIntl } from 'react-intl'

const useStyles = makeStyles(theme => ({
  ObjectLoader3D_MainContainer: {
    position: 'relative',
    width: 'min(100%, 600px)',
    margin: '8px auto',
  },
  ObjectLoader3D_container: {
    border: `4px solid ${theme.palette.content.accent}`,
    width: 'min(100%, 600px)',
    aspectRatio: 4 / 3,
  },
  ObjectLoader3D_Icon: {
    position: 'relative',
    left: 16,
    top: -48,
    fontSize: 32,
  },
}))

const ObjectLoader3D = () => {
  const c = useStyles()
  const container = useRef(null)
  const [isSceneSetUp, setIsSceneSetUp] = useState(false)
  const intl = useIntl()

  useEffect(() => {
    const width = container.current.clientWidth
    const height = container.current.clientHeight
    const scene = new THREE.Scene()
    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true })
    renderer.setPixelRatio(window.devicePixelRatio)
    const camera = new THREE.PerspectiveCamera(75, width / height, 0.01, 50)

    let models = []

    const setUpScene = () => {
      camera.position.z = -3
      camera.position.y = 0
      camera.position.x = 0

      const controls = new OrbitControls(camera, renderer.domElement)
      controls.update()
      renderer.setSize(width, height)
      container.current.appendChild(renderer.domElement)
    }

    const loadModel = () => {
      const loader = new GLTFLoader()

      // load a resource
      loader.load(
        // resource URL
        Object2,
        // called when resource is loaded
        function (obj) {
          obj.scene.getObjectByName('Rahmen001').position.y = 0
          scene.add(obj.scene)

          //camera.lookAt(objPosition)
          /*
          const model = scene.getObjectByName('NURBS-Pfad.001')
          model.material.color.set(0x62e5ff)

          const box = scene.getObjectByName('Rahmen.001')
          box.material.color.set(0x949494)
          box.position.x = 0
          box.position.y = 0
          box.position.z = 0
          

          // make this element available inside of the whole component to do any animation later
          models = [model, box]
          */
        },
        // called when loading is in progresses
        function (xhr) {
          console.log(xhr)
          console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
        },
        // called when loading has errors
        function (error) {
          console.log('An error happened')
        }
      )
    }

    const addLights = () => {
      const lights = []

      // set color and intensity of lights
      lights[0] = new THREE.PointLight(0xffffff, 1, 0)
      lights[1] = new THREE.PointLight(0xffffff, 1, 0)
      lights[2] = new THREE.PointLight(0xffffff, 1, 0)

      // place some lights around the scene for best looks and feel
      lights[0].position.set(0, 2000, 0)
      lights[1].position.set(1000, 2000, 1000)
      lights[2].position.set(-1000, -2000, -1000)

      scene.add(lights[0])
      scene.add(lights[1])
      scene.add(lights[2])
      //scene.add(new THREE.AmbientLight(0xcccccc, 0.4))
    }

    let loop = false

    const startAnimationLoop = () => {
      if (!loop) {
        loop = true
      }
      // slowly rotate all objects
      models.forEach(m => {
        //m.rotation.y += 0.005
      })

      renderer.render(scene, camera)

      // The window.requestAnimationFrame() method tells the browser that you wish to perform
      // an animation and requests that the browser call a specified function
      // to update an animation before the next repaint
      let requestID = window.requestAnimationFrame(startAnimationLoop)
    }

    const resizeHandler = () => {
      if (!container.current) return
      const { clientWidth, clientHeight } = container.current

      const parsedHeight = clientWidth * 0.66
      container.current.height = parsedHeight

      renderer.setSize(clientWidth, clientHeight)
      camera.aspect = clientWidth / clientHeight

      // Note that after making changes to most of camera properties you have to call
      // .updateProjectionMatrix for the changes to take effect.
      camera.updateProjectionMatrix()
    }

    if (!isSceneSetUp) {
      setUpScene()
      loadModel()
      addLights()
      startAnimationLoop()
      window.addEventListener('resize', resizeHandler)
      setIsSceneSetUp(true)
    }
  }, [])

  const getHeight = () => {
    if (container.current) {
      return container?.current?.clientWidth * 0.66
    } else return 100
  }

  return (
    <div className={c.ObjectLoader3D_MainContainer}>
      <div ref={container} className={c.ObjectLoader3D_container}></div>
      <Tooltip title={intl.formatMessage({ id: 'shared.3dTooltip' })}>
        <ThreeDRotationIcon className={c.ObjectLoader3D_Icon}></ThreeDRotationIcon>
      </Tooltip>
    </div>
  )
}

export default ObjectLoader3D
