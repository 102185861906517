import { createTheme } from '@mui/material/styles'

export default createTheme({
  palette: {
    primary: {
      main: '#203956',
      mainTransparent: '#203956',
      white: '#FFFFFF',
      green: '#6FC380',
      variant1: '#203956',
      variant2: '#203956',
      variant3: '#203956',
      variant4: '#203956',
    },
    secondary: {
      main: '#5BA0F2',
      gray: '#707070',
      lightGray: '#C0C0C0',
      variant1: '#5BA0F2',
      variant2: '#5BA0F2',
      variant3: '#5BA0F2',
      variant4: '#5BA0F2',
    },
    premium: {
      main: '#64071A',
    },
    practiceMode: {
      primary: '#F9DFC0',
    },
    content: {
      accent: '#5BA0F2',
    },
    chat: {
      background: '#5BA0F2',
      foreground: '#333333',
    },
    background: {
      default: '#FFFFFF',
      light: '#fefefe',
      colored: '#EEF5FE',
      cookie: '#54595f',
    },
    disabled: {
      main: '#707070',
    },
    error: {
      main: '#ba2424',
    },
    shadow: {
      default: '#00000029',
    },
    header: {
      background: 'rgba(255, 255, 255, 0.65)',
    },
  },
  overrides: {
    MuiDivider: {
      root: {
        backgroundColor: '#707070',
      },
    },
  },
})
