import React, { useState } from 'react'
import CardActionArea from '@mui/material/CardActionArea'
import Paper from '@mui/material/Paper'
import { FormattedMessage, useIntl } from 'react-intl'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import LinesEllipsisLoose from 'react-lines-ellipsis/lib/loose'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

const myStyles = makeStyles(theme => ({
  IntroductionCard_Card: {
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(550)]: {
      margin: theme.spacing(2),
    },
    boxShadow: 'none',
    '-webkit-box-shadow': 'none',
    borderRadius: 0,
  },
  IntroductionCard_SubTitle: {
    fontSize: '19px',
    lineHeight: '26px',
    padding: theme.spacing(2),
    [theme.breakpoints.down(550)]: {
      fontSize: '16px',
      lineHeight: '24px',
      padding: theme.spacing(1),
    },
  },
}))

const IntroductionCard = ({ notExpandable = false }) => {
  const c = myStyles()
  const intl = useIntl()
  const [expanded, setExpanded] = useState(false)

  if (notExpandable) {
    return (
      <Paper className={c.IntroductionCard_Card}>
        <Typography variant='subtitle1' className={c.IntroductionCard_SubTitle}>
          <FormattedMessage id={'shared.welcomeIntro'}></FormattedMessage>
        </Typography>
      </Paper>
    )
  }

  return (
    <Paper className={c.IntroductionCard_Card}>
      <CardActionArea onClick={() => setExpanded(!expanded)}>
        <Grid container direction='row' justifyContent='space-between' alignItems='stretch'>
          <Grid item md={11} sm={11}>
            <Typography variant='subtitle1' className={c.IntroductionCard_SubTitle}>
              <LinesEllipsisLoose
                text={intl.formatMessage({ id: 'shared.welcomeIntro' })}
                maxLine={!expanded ? 1 : 100}
                lineHeight='28'
                className={c.BlockWithIcon_LinesEllipsis}
              />
            </Typography>
          </Grid>
          <Grid
            item
            container
            md={1}
            sm={1}
            justifyContent='center'
            alignItems='flex-start'
            style={{ paddingTop: '16px' }}
          >
            {expanded ? (
              <KeyboardArrowUpIcon></KeyboardArrowUpIcon>
            ) : (
              <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
            )}
          </Grid>
        </Grid>
      </CardActionArea>
    </Paper>
  );
}

export default IntroductionCard
