import { createSlice } from '@reduxjs/toolkit'
import { STATUSES } from '@constants/slices'
import { clearCurrentUser, setAuthToken, setCurrentUser } from '@services/currentUser'
import api from '@services/api'
import { STORAGE_KEY_DASHBOARD } from '@pages/Dashboard/slice'

const slice = createSlice({
    name: 'login',
    initialState: {
        login: {
            status: STATUSES.INIT,
        },
        tokenValidation: {
            status: STATUSES.INIT,
        },
        value: {},
        errorStatus: null,
    },
    reducers: {
        loading: (state, { payload: { method } }) => {
            return {...state, errorStatus: null, [method]: { status: STATUSES.LOADING } }
        },

        loaded: (state, { payload: { method, data } }) => {
            return {
                ...state,
                errorStatus: null,
                value: data,
                [method]: { status: STATUSES.LOADED },
            }
        },

        error: (state, { payload: { method, errorStatus } }) => {
            return {...state, errorStatus, [method]: { status: STATUSES.ERROR } }
        },
    },
})

export const login = (username, password) => dispatch => {
    dispatch(loading({ method: 'login' }))

    return api({
            method: 'POST',
            areaPath: '/memberarea',
            endpoint: '/token',
            body: { username, password },
        })
        .then(res => {
            const {
                token,
                user_display_name: name,
                user_email: email,
                user_nicename: nickname,
            } = res.data || {}
            if (token) {
                setAuthToken(token)
                setCurrentUser({ name, nickname, email })

                dispatch(loaded({ method: 'login', data: res.data }))

                return Promise.resolve()
            } else {
                return Promise.reject()
            }
        })
        .catch(e => {
            const status = e.response ? e.response.status : 500
            dispatch(error({ method: 'login', errorStatus: status }))
            return Promise.reject()
        })
}

export const logout = () => {
    clearCurrentUser()
    localStorage.removeItem(STORAGE_KEY_DASHBOARD)
    window.location.href = '/login'

    return Promise.resolve()
}

export const selectLoginStatus = state => state.login.login.status
export const selectLoginErrorStatus = state => state.login.errorStatus

const { loaded, loading, error } = slice.actions
export default slice.reducer