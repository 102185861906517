import { COOKIE_CONSENT_COOKIE_NAME, YEAR_IN_MS } from '@components/atoms/CookieBanner'
import { CookieDetailRow } from '@components/atoms/CookieDetailRow'
import { Button, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => {
  return {
    CookieSettings_Card: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    CookieSettings_detailTitle: {
      fontSize: 20,
      color: theme.palette.primary.main,
      [theme.breakpoints.down(550)]: {
        fontSize: 18,
      },
    },
    CookieSettings_detailInfo: {
      fontSize: 16,
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down(550)]: {
        fontSize: 14,
      },
    },
  }
})

export const CookieSettings = ({ onUpdate }) => {
  const intl = useIntl()
  const c = useStyles()
  const history = useHistory()
  const [gaAccepted, setGaAccepted] = useState(false)
  const [chatAccepted, setChatAccepted] = useState(false)

  useEffect(() => {
    const cookies = document.cookie?.split(';')
    let consentCookie = cookies.find(c => c.includes(COOKIE_CONSENT_COOKIE_NAME))
    if (consentCookie) {
      let consentData
      try {
        consentData = JSON.parse(consentCookie.split('=')[1])
      } catch {
        console.log('Cookie parsing failed')
      }
      if (consentData) {
        setGaAccepted(consentData.tracking)
        setChatAccepted(consentData.chat)
      }
    }
  }, [])

  const saveAndAccept = () => {
    const consentData = {
      tracking: gaAccepted,
      chat: chatAccepted,
    }
    const expires = new Date(Date.now() + YEAR_IN_MS)
    document.cookie = `${COOKIE_CONSENT_COOKIE_NAME}=${JSON.stringify(
      consentData
    )}; expires=${expires}; path=/`
    onUpdate()
  }

  return (
    <Paper square className={c.CookieSettings_Card} elevation={2}>
      <Typography className={c.CookieSettings_detailTitle} component='h4'>
        <FormattedMessage id='cookieBanner.detailTitle'></FormattedMessage>
      </Typography>
      <Typography className={c.CookieSettings_detailInfo}>
        <FormattedMessage
          id='cookieBanner.detailInfo'
          values={{
            cookieSettings: (
              <Link onClick={() => history.push('/cookies')} variant='standard'>
                {intl.formatMessage({ id: 'shared.myProfile' })}
              </Link>
            ),
            privacy: (
              <a
                href='https://plakos-akademie.de/datenschutz/'
                target='_blank'
                rel='noreferrer'
              >
                {intl.formatMessage({ id: 'footer.privacyPolicy' })}
              </a>
            ),
          }}
        ></FormattedMessage>
      </Typography>
      <Grid container directon='column' justifyContent='center'>
        <CookieDetailRow
          info={intl.formatMessage({ id: 'cookieBanner.description' })}
          time={intl.formatMessage({ id: 'cookieBanner.saveTime' })}
        ></CookieDetailRow>
        <CookieDetailRow
          info={intl.formatMessage({ id: 'cookieBanner.neccassaryInfo' })}
          time={intl.formatMessage({ id: 'cookieBanner.neccassaryTime' })}
        ></CookieDetailRow>
        <CookieDetailRow
          checked={gaAccepted}
          setChecked={v => setGaAccepted(v)}
          info={intl.formatMessage({ id: 'cookieBanner.trackingInfo' })}
          time={intl.formatMessage({ id: 'cookieBanner.trackingTime' })}
        ></CookieDetailRow>
        <CookieDetailRow
          checked={chatAccepted}
          setChecked={v => setChatAccepted(v)}
          info={intl.formatMessage({ id: 'cookieBanner.chatInfo' })}
          time={intl.formatMessage({ id: 'cookieBanner.chatTime' })}
        ></CookieDetailRow>
        <Grid container item justifyContent='end'>
          <Button onClick={saveAndAccept}>
            <Typography>
              <FormattedMessage id='cookieBanner.saveAndAccept'></FormattedMessage>
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}
