import { Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import clsx from 'clsx'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  FilterButton_Base: {
    border: `1px solid ${theme.palette.secondary.gray}`,
    minHeight: '32px',
    display: 'inline-flex',
    padding: theme.spacing(1),
    cursor: 'pointer',
  },
  FilterButton_Active: {
    backgroundColor: theme.palette.primary.main,
  },

  FilterButton_Inactive: {
    backgroundColor: theme.palette.secondary.lightGray,
  },

  FilterButton_Hover: {
    backgroundColor: theme.palette.secondary.gray,
  },

  FilterButton_Text: {
    color: 'white',
    fontSize: '18px',
  },
}))

const FilterButton = ({ clickhandler, text, active }) => {
  const c = useStyles()
  const [isHover, setIsHover] = useState(false)
  return (
    <div
      onClick={clickhandler}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={clsx(
        c.FilterButton_Base,
        active
          ? c.FilterButton_Active
          : isHover
            ? c.FilterButton_Hover
          : c.FilterButton_Inactive
      )}
    >
      <Typography className={c.FilterButton_Text}>{text}</Typography>
    </div>
  )
}

export default FilterButton
