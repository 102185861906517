import { STATUSES } from '@constants/slices'
import { syncDashboard } from '@pages/Dashboard/slice'
import { createSlice } from '@reduxjs/toolkit'
import {
  getCourseContentById,
  getNextContentNode,
  getPreviousContentNode,
  getCourseTree as fetchCourseTree,
  parseAndResolve,
} from '@services/courseCache'
import { sendCourseCompleted } from '@services/gaIntegration/slice'

export const courseOverviewSlice = createSlice({
  name: 'courseOverview',
  initialState: {
    isOpen: true,
    courseTree: null,
    currentContent: null,
    courseTreeStatus: STATUSES.INIT,
  },
  reducers: {
    loading: state => ({ ...state, courseTreeStatus: STATUSES.LOADING }),
    loaded: (state, action) => ({
      ...state,
      courseTreeStatus: STATUSES.LOADED,
      courseTree: action.payload,
    }),
    error: state => ({ ...state, courseTreeStatus: STATUSES.ERROR }),
    open: state => ({ ...state, isOpen: true }),
    close: state => ({ ...state, isOpen: false }),
    setCurrentContent: (state, action) => ({ ...state, currentContent: action.payload }),
  },
})
export const updateProgress = value => (dispatch, getState) => {
  const currentContent = JSON.parse(
    JSON.stringify(getState().courseOverview.currentContent)
  )
  if (currentContent) {
    currentContent.progress = value
    dispatch(setCurrentContent(currentContent))
  }
}

export const setOpen = () => dispatch => {
  dispatch(open())
}

export const setClose = () => dispatch => {
  dispatch(close())
}

export const toggleOverview = isOpen => dispatch => {
  isOpen ? dispatch(close()) : dispatch(open())
}

export const getCourseTree = (courseId, id) => dispatch => {
  dispatch(loading())
  fetchCourseTree(courseId)
    .then(tree => {
      if (courseId !== id && id) {
        dispatch(setCurrentContent(getCourseContentById(tree, id)))
      } else {
        dispatch(setCurrentContent(tree))
      }
      dispatch(filterCourseTree(tree))
    })
    .catch(err => {
      console.log(err)
      dispatch(error())
    })
}

export const getCourseContent = (courseId, id) => (dispatch, getState) => {
  const tree = getState().courseOverview.courseTree
  const isSameCourse = tree && tree.id == courseId
  if (isSameCourse && tree) {
    dispatch(setCurrentContent(getCourseContentById(tree, id)))
  } else {
    dispatch(getCourseTree(courseId, id))
  }
}

export const updateCourseTree = (skipCurrent = false) => (dispatch, getState) => {
  const courseId = getState().courseOverview.courseTree.id
  fetchCourseTree(courseId)
    .then(tree => {
      if (!skipCurrent) {
        const contentId = getState().courseOverview.currentContent.id
        if (contentId && courseId !== contentId) {
          dispatch(setCurrentContent(getCourseContentById(tree, contentId)))
        } else {
          dispatch(setCurrentContent(tree))
        }
      }
      dispatch(filterCourseTree(tree))
    })
    .catch(err => {
      console.log(err)
      dispatch(error())
    })
}

export const resetCourseTreeProgress = courseId => (dispatch, getState) => {
  const tree = getState().courseOverview.courseTree
  if (!tree || tree.id != courseId) {
    dispatch(syncDashboard(courseId, 0))
    return
  }

  const root = getCourseContentById(tree, tree.id)

  const resetNode = node => {
    node.progress = 0
    node.progressData.contentDone = 0
    node.children.forEach(child => resetNode(child))
  }

  resetNode(root)
  dispatch(syncDashboard(root.id, root.progress))
  dispatch(loaded(root))
}

export const updateCourseTreeProgress = (courseId, id, value) => (dispatch, getState) => {
  const tree = getState().courseOverview.courseTree
  const currentContent = getState().courseOverview.currentContent
  if (!tree || tree.id != courseId) {
    return
  }

  const updateNode = (currentNode, lastNode) => {
    // replaces Childnode with already updated Node
    let index = currentNode.children.findIndex(c => c.id === lastNode.id)
    lastNode.surroundingContent = null
    currentNode.children[index] = lastNode

    let newContentDone = 0
    currentNode.children.forEach(c => {
      let spectatedChild = c.id === lastNode.id ? lastNode : c

      if (spectatedChild.children.length === 0) {
        if (spectatedChild.progress === 1) {
          newContentDone++
        }
      } else {
        newContentDone += spectatedChild.progressData.contentDone
      }
    })

    currentNode.progressData.contentDone = newContentDone
    const { amountTests, amountTopics, contentDone } = currentNode.progressData
    currentNode.progress = contentDone / (amountTests + amountTopics)
    return currentNode
  }

  let node = getCourseContentById(tree, id)
  node.progress = value

  let parent = node.surroundingContent
  let lastChild = node
  let root
  while (parent) {
    parent = updateNode(parent, lastChild)
    if (parent.type === 'course') {
      root = parent
      parent = null
    } else {
      lastChild = parent
      parent = parent.surroundingContent
    }
  }

  root = updateNode(root, lastChild)
  dispatch(syncDashboard(root.id, root.progress))
  if (root.progress === 1) {
    dispatch(sendCourseCompleted(root))
  }
  dispatch(loaded(root))
  dispatch(setCurrentContent(getCourseContentById(root, currentContent.id)))
}

export const filterCourseTree = tree => dispatch => {
  const courseTree = JSON.parse(JSON.stringify(tree))
  courseTree.children = courseTree.children.filter(
    (value, index, self) => index === self.findIndex(t => t.id === value.id)
  )
  dispatch(loaded(courseTree))
}

export const selectCourseOverviewIsOpen = state => state.courseOverview.isOpen
export const selectCourseTreeStatus = state => state.courseOverview.courseTreeStatus
export const selectCourseTree = state => state.courseOverview.courseTree
export const selectCurrentContent = state => state.courseOverview.currentContent

export const {
  loading,
  loaded,
  error,
  open,
  close,
  setCourseTree,
  setCurrentContent,
} = courseOverviewSlice.actions
export default courseOverviewSlice.reducer
