import React from 'react'
import { makeStyles } from '@mui/styles'
import { useHistory } from 'react-router-dom'
import StarIcon from '@mui/icons-material/Star'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'

import { FormattedMessage, useIntl } from 'react-intl'
import clsx from 'clsx'
import CardActionArea from '@mui/material/CardActionArea'
import { Grid } from '@mui/material'

const useStyles = makeStyles(theme => ({
  NotepadCard: {},
  NotepadCard_Container: {
    margin: `0px ${theme.spacing(4)} ${theme.spacing(2)} ${theme.spacing(0)}`,
    width: `calc(100% - ${theme.spacing(4)})`,
    background: 'none',
    [theme.breakpoints.down('md')]: {
      margin: `${theme.spacing(2)} ${theme.spacing(3)}`,
      width: `calc(100% - ${theme.spacing(6)})`,
    },
  },
  NotepadCard_Container__withoutMargin: {
    width: '100%',
    background: 'none',
  },
  NotepadCard_HeaderShape: {
    display: 'inline-flex',
    width: 'auto',
    minWidth: '150px',
  },
  NotepadCard_Header: {
    padding: ` 0px ${theme.spacing(1)}`,
    backgroundColor: theme.palette.primary.variant2,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  NotepadCard_MainContainer: {
    background: 'none',
  },
  NotepadCard_ContentContainer: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(1),
    },
  },
  NotepadCard_Title: {
    color: 'white',
    padding: theme.spacing(1),
    fontSize: 20,
    fontWeight: 'bold',
  },
  NotepadCard_Icon: {
    color: 'white',
  },
  NotepadCard_Text: {
    fontSize: '16px',
    lineHeight: '21px',
    opacity: '0.87',
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down(768)]: {
      fontSize: '14px',
      lineHeight: '18px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  NotepadCard_OneLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  NotepadCard_Button: {
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    paddingBottom: '0px',
    textTransform: 'uppercase',
    [theme.breakpoints.down(600)]: {
      fontSize: '12px',
      lineHeight: '16px',
      paddingBottom: '0',
    },
  },
}))

const NotepadCard = ({ notepad, handleAction, isMobileView = false }) => {
  const c = useStyles()
  const intl = useIntl()
  const history = useHistory()

  const { numberOfQuestions, numberOfTests } = notepad

  return (
    <div
      className={
        isMobileView ? c.NotepadCard_Container__withoutMargin : c.NotepadCard_Container
      }
    >
      <div className={c.NotepadCard_HeaderShape}>
        <CardActionArea onClick={handleAction}>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            className={c.NotepadCard_Header}
          >
            <StarIcon className={c.NotepadCard_Icon} />
            <Typography className={clsx(c.NotepadCard_Title, c.NotepadCard_OneLine)}>
              {intl.formatMessage({ id: 'shared.notepad' })}
            </Typography>
          </Grid>
        </CardActionArea>
      </div>
      <CardActionArea onClick={handleAction}>
        <Paper className={c.NotepadCard_MainContainer} square>
          <Grid
            container
            direction='column'
            justifyContent='flex-start'
            className={c.NotepadCard_ContentContainer}
          >
            <Typography className={clsx(c.NotepadCard_Text, c.NotepadCard_OneLine)}>
              <FormattedMessage
                values={{
                  questions: numberOfQuestions,
                  tests: numberOfTests,
                }}
                id='shared.notepadInfo'
              />
            </Typography>
            <Typography className={c.NotepadCard_Button} color='primary'>
              {intl.formatMessage({ id: 'shared.start' })}
            </Typography>
          </Grid>
        </Paper>
      </CardActionArea>
    </div>
  );
}

export default NotepadCard
