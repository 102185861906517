import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'
import { Button } from '@mui/material'
import ArrowUpward from '@mui/icons-material/ArrowUpward'

const useStyles = makeStyles(theme => ({
  ScrollToTopButton_Container: {
    position: 'fixed',
    backgroundColor: theme.palette.primary.main,
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    boxShadow: '0px 5px 4px rgba(0, 0, 0, 0.26)',
    '-webkit-box-shadow': '-0px 5px 4px rgba(0, 0, 0, 0.26)',
    bottom: '16px',
    left: '16px',
    zIndex: 100,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  ScrollToTopButton_Icon: {
    color: 'white',
    width: '30px',
    height: '30px',
  },
}))

const ScrollToTopButton = () => {
  const [visible, setVisible] = useState()
  const c = useStyles()

  useEffect(() => {
    const callBack = e => {
      setVisible(window.pageYOffset > 300)
    }
    document.addEventListener('scroll', callBack)

    return () => {
      document.removeEventListener('scroll', callBack)
    }
  })

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return visible ? (
    <Button className={c.ScrollToTopButton_Container} onClick={scrollToTop}>
      <ArrowUpward className={c.ScrollToTopButton_Icon}></ArrowUpward>
    </Button>
  ) : (
    <></>
  )
}

export default ScrollToTopButton
