import { createSlice } from '@reduxjs/toolkit'
import ReactGA from 'react-ga4'
import Cookies from 'universal-cookie'
import { emitter } from '@marvelapp/react-ab-test'
import React from 'react'

const GA_ID = 'G-7V4F1FDQS0'

export const gaIntegrationSlice = createSlice({
  name: 'gaIntegration',
  initialState: {
    isActive: false,
  },
  reducers: {
    activate: state => ({ ...state, isActive: true }),
    deactivate: state => ({
      ...state,
      isActive: false,
    }),
  },
})

export const activateGA = () => (dispatch, getState) => {
  if (getState().gaIntegration.isActive) return

  try {
    window[`ga-disable-${GA_ID}`] = false
    ReactGA.initialize(GA_ID, {
      cookieExpires: 31536000,
    })
    ReactGA.set({
      user_properties: { user_variant: emitter.getActiveVariant('ThemeTest') },
    })
    dispatch(activate())
  } catch (e) {
    console.log('Error with GA')
  }
}

export const deactivateGA = () => dispatch => {
  const cookies = new Cookies()
  let pureId = GA_ID.slice(2)

  //deactivate GA
  window[`ga-disable-${GA_ID}`] = true
  cookies.remove('_ga')
  cookies.remove(`_ga_${pureId}`)
  dispatch(deactivate())
}

export const sendPageView = pageTitle => (dispatch, getState) => {
  if (getState().gaIntegration.isActive) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log('DEV: Pageview', pageTitle)
    } else {
      console.log('Fireing')
      ReactGA.send({
        hitType: 'pageview',
        page: pageTitle,
      })
    }
  }
}

export const sendCourseCompleted = course => (dispatch, getState) => {
  if (getState().gaIntegration.isActive) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      console.log('DEV: Send Course Completed', course?.title)
    } else {
      ReactGA.event('course_completed', {
        course_title: course.title,
        course_id: course.id,
      })
    }
  }
}

export const selectGAStatus = state => state.gaIntegration.isActive

export const { activate, deactivate, error } = gaIntegrationSlice.actions
export default gaIntegrationSlice.reducer
