import { Backdrop } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import PremiumOffer from '../Shop/PremiumOffer'

import SearchItem from './SearchItem'

const SearchResults = ({
  onBackdropSet,
  onSearchValueSet,
  filteredSearchItems,
  specialMessage,
  isMobile,
}) => {
  const filterItemsCount = filteredSearchItems?.length
  const [isOpen, setIsOpen] = useState(false)

  if (specialMessage) {
    return (
      <SearchItem
        specialResult={specialMessage}
        onBackdropSet={onBackdropSet}
        onSearchValueSet={onSearchValueSet}
      />
    )
  }
  return (
    <>
      <PremiumOffer isOpen={isOpen} closeCallback={() => setIsOpen(false)}></PremiumOffer>
      {filteredSearchItems.map((item, index) => (
        <SearchItem
          {...item}
          key={`${item.id}${item.title}${item.courseName}`}
          courseName={item?.courseName}
          divider={
            filterItemsCount % 2 === 0
              ? index < filterItemsCount - 2
              : filterItemsCount - 1 !== index
          }
          onBackdropSet={onBackdropSet}
          onSearchValueSet={onSearchValueSet}
          isMobile={isMobile}
          noNavHandler={() => setIsOpen(true)}
        />
      ))}
    </>
  )
}

export default SearchResults
