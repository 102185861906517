import { STATUSES } from '@constants/slices'
import { createSlice } from '@reduxjs/toolkit'
import api from '@services/api'

export const testSlice = createSlice({
  name: 'test',
  initialState: {
    value: {},
    status: STATUSES.INIT,
  },
  reducers: {
    loaded: (state, { payload }) => ({
      ...state,
      value: payload,
      status: STATUSES.LOADED,
    }),
    loading: state => ({ ...state, status: STATUSES.LOADING }),
    error: state => ({ ...state, status: STATUSES.ERROR }),

    progress: (state, action) => {
      state.value = {
        ...state.value,
        progress: action.payload.progress,
      }
    },
  },
})

export const getTest = (courseId, testId) => dispatch => {
  dispatch(loading())
  api({ endpoint: `/tests/${courseId}/${testId}` })
    .then(res => {
      dispatch(loaded(res.data))
    })
    .catch(() => dispatch(error()))
}

export const putProgress = (courseId, testId) => dispatch =>
  api({
    endpoint: `/progress/${courseId}`,
    method: 'PUT',
    body: {
      id: testId,
      progress: 1,
    },
  })
    .then(res => {
      dispatch(progress(res.data))
      return Promise.resolve()
    })
    // eslint-disable-next-line no-console
    .catch(console.log)

export const selectTest = state => state.test.value
export const selectTestStatus = state => state.test.status

export const { loaded, loading, error, progress } = testSlice.actions
export default testSlice.reducer
