import {
  Box,
  Button,
  CardActionArea,
  CircularProgress,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import React, { useState } from 'react'
import clsx from 'clsx'
import Avatar from 'react-avatar'
import { useHistory } from 'react-router-dom'
import LinesEllipsisLoose from 'react-lines-ellipsis/lib/loose'
import { FormattedMessage } from 'react-intl'
import PriceInCoins from '@components/atoms/PriceInCoins'
import { selectShop, updateFreeCourseEnrollment } from '@pages/Shop/slice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { STATUSES } from '@constants/slices'

const useStyles = makeStyles(theme => ({
  ShopCourseCard_Nested_Base: {
    alignSelf: 'stretch',
    transition: 'all 0.3s ease-out',
    flexShrink: 0,
    overflow: 'hidden',
    cursor: 'pointer',
    [theme.breakpoints.down(550)]: {
      position: 'absolute',
      zIndex: 100,
      height: '112px',
    },
  },
  ShopCourseCard_Nested: {
    width: 100,
    [theme.breakpoints.down(550)]: {
      width: 88,
    },
  },
  ShopCourseCard_Nested_Hidden: {
    width: 0,
  },
  ShopCourseCard_Nested_active: {
    backgroundColor: theme.palette.primary.variant1,
  },
  ShopCourseCard_NestedText_active: {
    color: 'white',
  },
  ShopCourseCard_NestedText_inactive: {
    color: theme.palette.primary.main,
  },
  ShopCourseCard_Nested_inactive: {
    backgroundColor: theme.palette.background.colored,
  },
  BlockWithIcon: {
    margin: '8px 0px',
  },
  BlockWithIcon_Icon: {
    height: theme.spacing(6),
    width: theme.spacing(6),
  },
  BlockWithIcon_Title: {
    fontSize: '21px',
    lineHeight: '28px',
    height: '28px',
    [theme.breakpoints.down(550)]: {
      fontSize: '16px',
      lineHeight: '21px',
    },
  },
  BlockWithIcon_Text: {
    fontSize: '12px',
    lineHeight: '16px',
    height: '32px',
    opacity: '0.6',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  BlockWithIcon_AvatarWrapper: {
    width: '114px',
    height: '114px',
    fontSize: '40px',
    flexShrink: '0',
    padding: 0,
    [theme.breakpoints.down(550)]: {
      width: '80px',
      height: '80px',
      fontSize: '20px',
      padding: 0,
    },
    margin: `${theme.spacing(1)} 0px ${theme.spacing(1)} ${theme.spacing(1)}`,
  },
  BlockWithIcon_TextTooltip: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  BlockWithIcon_ButtonContainer: {
    width: '100%',
    textAlign: 'left',
    gap: theme.spacing(2),
    [theme.breakpoints.down(400)]: {
      gap: 0,
    },
  },
  BlockWithIcon_Button: {
    paddingRight: 0,
    fontSize: '17px',
    lineHeight: '23px',
    letterSpacing: '0.02px',
    fontWeight: '500',
    textTransform: 'uppercase',
    [theme.breakpoints.down(550)]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  BlockWithIcon_ButtonBase: {
    minWidth: '120px',
  },
  BlockWithIcon_LinearProgress: {
    marginBottom: '8px',
  },
  BlockWithIcon_OneLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  BlockWithIcon_IconContainer: {
    width: '100%',
    margin: `${theme.spacing(1)} 0px`,
  },
  BlockWithIcon_LinesEllipsis: {
    wordBreak: 'break-all',
  },
  BlockWithIcon_withoutTooltip: {},
  BlockWithIcon_AvatarAction: {
    height: '100%',
    fontSize: 'inherit',
  },
  BlockWithIcon_ContentContainer: {
    padding: `0px ${theme.spacing(1)}`,
  },
}))

const getText = (lessons = []) => lessons.map(item => item?.title).join(' - ')

const ShopCourseCard = ({
  handleClick = null,
  handleSubClick = null,
  course,
  isFree,
}) => {
  const { title, icon, lessons, pricing, userIsSubscribedToFree } = course
  const c = useStyles()
  const [isHovering, setIsHovering] = useState(false)
  const [isNestedHovering, setIsNestedHovering] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEnrolled, setIsEnrolled] = useState(userIsSubscribedToFree)
  const { status, value } = useSelector(selectShop)
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  useEffect(() => {
    if (status === STATUSES.LOADED && value.id === course.id) {
      setLoading(false)
      setIsEnrolled(!isEnrolled)
    }
  }, [status])

  const addCourse = course => {
    setLoading(true)
    dispatch(updateFreeCourseEnrollment(course.id, course.userIsSubscribedToFree))
  }

  const handleClickWrapper = course => {
    if (isFree) {
      addCourse(course)
    } else {
      handleClick(course)
    }
  }

  const text = getText(lessons)
  return (
    <Paper square className={c.BlockWithIcon}>
      <Box
        onMouseEnter={() => setTimeout(() => setIsHovering(true), 10)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Grid container spacing={0} wrap='nowrap' alignItems='center'>
          <Grid
            container
            item
            justifyContent='center'
            alignItems='center'
            className={clsx(
              c.ShopCourseCard_Nested_Base,
              isHovering ? c.ShopCourseCard_Nested : c.ShopCourseCard_Nested_Hidden,
              isHovering
                ? isNestedHovering
                  ? c.ShopCourseCard_Nested_active
                  : c.ShopCourseCard_Nested_inactive
                : null
            )}
            onMouseEnter={e => {
              if (!isHovering) {
                setTimeout(() => setIsNestedHovering(true), 10)
              } else {
                setIsNestedHovering(true)
              }
            }}
            onMouseLeave={() => setIsNestedHovering(false)}
            onMouseDown={() => {
              if (isNestedHovering) handleSubClick(course)
            }}
          >
            {isHovering ? (
              isFree ? (
                <Button>
                  <Typography
                    className={clsx(
                      c.BlockWithIcon_Button,
                      isNestedHovering
                        ? c.ShopCourseCard_NestedText_active
                        : c.ShopCourseCard_NestedText_inactive
                    )}
                  >
                    <FormattedMessage id='shared.view' />
                  </Typography>
                </Button>
              ) : (
                <PriceInCoins
                  price={pricing?.regularPrice}
                  color={isNestedHovering ? 'white' : theme.palette.primary.main}
                ></PriceInCoins>
              )
            ) : null}
          </Grid>
          <Grid item className={clsx(c.BlockWithIcon_AvatarWrapper)}>
            <CardActionArea
              onClick={() => {
                if (!isHovering) setIsHovering(true)
              }}
              className={c.BlockWithIcon_AvatarAction}
            >
              <Avatar
                className={c.BlockWithIcon_Icon}
                src={icon}
                color={
                  !course.courseIsFree
                    ? theme.palette.primary.main
                    : theme.palette.secondary.variant2
                }
                size='100%'
                round={true}
                title={title}
                name={title.replace(/[^a-zA-Z\s]+/g, '')}
                maxInitials={2}
              />
            </CardActionArea>
          </Grid>
          <Grid item className={c.BlockWithIcon_IconContainer}>
            <CardActionArea
              onClick={() => handleClickWrapper(course)}
              className={c.BlockWithIcon_ContentContainer}
            >
              <div>
                <Typography className={clsx(c.BlockWithIcon_Title)} component='div'>
                  <LinesEllipsisLoose
                    text={title}
                    maxLine='1'
                    lineHeight='28'
                    className={c.BlockWithIcon_LinesEllipsis}
                  />
                </Typography>
              </div>
              <Tooltip title={text} classes={{ tooltip: c.BlockWithIcon_TextTooltip }}>
                <Typography className={clsx(c.BlockWithIcon_Text)} component='div'>
                  <LinesEllipsisLoose
                    text={text}
                    style={{ wordBreak: 'normal' }}
                    maxLine='2'
                    lineHeight='16'
                    className={c.BlockWithIcon_LinesEllipsis}
                  />
                </Typography>
              </Tooltip>
            </CardActionArea>
            <Grid
              className={c.BlockWithIcon_ButtonContainer}
              container
              item
              justifyContent='flex-end'
            >
              <Button
                onClick={() => handleClickWrapper(course)}
                className={c.BlockWithIcon_ButtonBase}
              >
                {!loading ? (
                  <Typography className={c.BlockWithIcon_Button} color='primary'>
                    <FormattedMessage
                      id={
                        !isFree
                          ? 'shared.buy'
                          : isEnrolled
                            ? 'shared.remove'
                          : 'shared.add'
                      }
                    />
                  </Typography>
                ) : (
                  <CircularProgress size={20}></CircularProgress>
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default ShopCourseCard
